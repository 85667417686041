import Calculator from './Views/Calculator';

function App() {
    const currYear: number = new Date().getFullYear();

    return (
        <div>

            <Calculator />

            <footer>

                <p>Click <a href="https://github.com/powersindustries" target="_blank">HERE</a> to see the source code for this website and many others.</p>

                <p>©{currYear} by Powers Industries, LLC</p>

            </footer>

        </div>
    );
}

export default App;
