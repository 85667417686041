class MrcState {
    public mPlus: number;
    public mMinus: number;

    constructor(mPlus: number, mMinus: number) {
        this.mPlus = mPlus;
        this.mMinus = mMinus;
    }
}

export default MrcState;
